import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import slug from '../lib/slug'

import format from 'date-fns/format'
import { parse } from '../lib/date_time'

const path = (event, activity) =>
  `/events/${format(parse(activity.date_time), 'yyyy/MM/dd')}/${slug(
    event.title
  )}`

const ActivityLink = ({ event, activity, children }) => (
  <Link to={path(event, activity)}>{children}</Link>
)

export const activityNavigate = (event, activity) => () =>
  navigate(path(event, activity))

export const ActivityLinkTD =
  (event, activity) =>
  ({ data, className }) =>
    (
      <td className={className}>
        <ActivityLink activity={activity} event={event}>
          {data}
        </ActivityLink>
      </td>
    )

export default ActivityLink

export const query = graphql`
  fragment ActivityLinkActivity on EventsYamlActivities {
    date_time
  }
  fragment ActivityLinkEvent on EventsYaml {
    title
  }
`
