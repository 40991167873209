import React from 'react'
import format from 'date-fns/format'
import { toActivities } from '../lib/events'

const TableRows = ({ cols, dateActivities, activity }) =>
  Object.entries(dateActivities).map(([date, activities]) => (
    <tbody key={date}>
      <tr className="thead-light">
        <th colSpan={cols}>{date}</th>
      </tr>
      {activities.map(activity)}
    </tbody>
  ))

const EventTable = ({
  className = '',
  title,
  events,
  header,
  activity,
  date_format = 'MMMM yyyy',
  sort = 'DESC',
  cols,
}) => {
  const sorted_activities = toActivities(events)

  if (sort === 'DESC') sorted_activities.reverse()

  const dateActivities = sorted_activities.reduce((acc, a) => {
    const key = format(a.date_time, date_format)
    if (!(key in acc)) acc[key] = []
    acc[key].unshift(a)
    return acc
  }, {})

  if (Object.keys(dateActivities).length <= 0) return null

  return (
    <table className={`table table-responsive event-list ${className}`}>
      <caption>{title}</caption>
      <thead className="thead-light">{header()}</thead>
      <TableRows
        cols={cols}
        dateActivities={dateActivities}
        activity={activity}
      />
    </table>
  )
}

export default EventTable
