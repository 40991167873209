import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import format from 'date-fns/format'

import EventTable from '../components/event_table'
import { ActivityLinkTD, activityNavigate } from '../components/activity_link'
import { DLSingleItem } from '../components/definition_list'
import { splitPastFuture } from '../lib/events'

const GroupEventTable = ({ title, events, sort }) => (
  <EventTable
    className="table-hover"
    title={title}
    events={events}
    sort={sort}
    cols={4}
    header={() => (
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Event</th>
        <th>Venue</th>
      </tr>
    )}
    activity={(activity, activity_index) => {
      const { date_time, event } = activity
      const ActivityLinkTDI = ActivityLinkTD(activity.event, activity)
      return (
        <tr
          key={activity.event.id + '.' + activity_index}
          onClick={activityNavigate(activity.event, activity)}
        >
          <ActivityLinkTDI data={format(date_time, 'dd')} className="nowrap" />
          <ActivityLinkTDI
            data={format(date_time, 'hh:mm a')}
            className="nowrap"
          />
          <ActivityLinkTDI data={event.title} />
          <ActivityLinkTDI data={event.venue.id} />
        </tr>
      )
    }}
  />
)

const filterGroup =
  (id) =>
  ({ group }) =>
    group.id === id

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    group: { id, website },
    events: { edges: event_nodes },
  },
}) {
  const { past, future } = splitPastFuture(
    event_nodes.map(({ node: e }) => e),
    filterGroup(id)
  )

  return (
    <Layout>
      <Helmet title={`${siteTitle} - ${id}`} />
      <div id="pageheader">
        <h1>Group</h1>
        <span>{id}</span>
      </div>
      <dl className="dl-horizontal">
        <DLSingleItem name="Name" data={id} />
        <DLSingleItem
          name="Website"
          data={
            !website ? null : (
              <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
            )
          }
        />
      </dl>
      <GroupEventTable title="Upcoming Events" events={future} sort="ASC" />
      <GroupEventTable title="Past Events" events={past} sort="DESC" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query GroupBySlug($slug: String!) {
    group: groupsYaml(slug: { eq: $slug }) {
      id
      slug
    }
    events: allEventsYaml(filter: { published: { eq: true } }) {
      edges {
        node {
          ...ActivityLinkEvent
          title
          published
          group {
            id
            slug
            website
          }
          venue {
            id
            slug
          }
          activities {
            ...ActivityLinkActivity
            name
            public
            duration
            created_date_time
            date_time
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
