import React from 'react'

export const DLItem = ({ name, children }) => (
  <>
    <dt>{name}</dt>
    {children}
  </>
)

export const DLSingleItem = ({ name, data }) => {
  if (!data) return null

  return (
    <DLItem name={name}>
      <dd>{data}</dd>
    </DLItem>
  )
}

export const DLDoubleItem = ({ name, data1, data2 }) => (
  <DLItem name={name}>
    {data1 && <dd>{data1}</dd>}
    {data2 && <dd>{data2}</dd>}
  </DLItem>
)
